import axios from "axios";
import { getToken, removeToken } from "./auth";
import router from "@/router";
import store from "@/store";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + "/api/imm",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers["Authorization"] = getToken();
    // console.log(config.headers);
    // do something before request is sent
    if (store.state.token) {
      config.headers["Authorization"] = getToken();
    }
    return config;
  },
  error => {
    // do something with request error

    console.log("request error : " + error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    return res;
  },
  error => {
    if (error.response != null || error.response != undefined) {
      if (error.response.status == 401) {
        // console.log('401 ok')
        // console.log(router)
        removeToken();
        router.push({ name: "Login" }).catch(e => console.log(e));
      }
    }

    console.log("response error : " + error.response.status);
    // for debug
    // return error.response
    return Promise.reject(error.response);
  }
);

export default service;
