import req from "../utils/req";

export function find(data) {
  return req({
    url: `/find`,
    method: "post",
    data: data
  });
}

export function queuelist(data) {
  return req({
    url: `/queuelist`,
    method: "post",
    data: data
  });
}
