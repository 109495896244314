<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <search-form @finding="findData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <search-result :results="results" :loading="loading" :total="total" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { find } from "../api/search";
export default {
  components: {
    searchForm: () => import("./panels/searchForm"),
    searchResult: () => import("./panels/searchResult")
  },
  data() {
    return {
      loading: false,
      results: [],
      total: 0
    };
  },
  methods: {
    findData(data) {
      this.loading = true;
      find(data)
        .then((res) => {
          this.results = res.results;
          this.total = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
</style>